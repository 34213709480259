

const getText = (el) => {
  let out = el.props && el.props.alt
  if (!out) {
    out = el
  }
  return out
}


const TRACKER = (() => {
  const data = {
    token: '',
    start: new Date(),
    events: [],
  }
  
  const getEvent = (type, el) => {
    return {
      type,
      data: getText(el),
      time: (new Date() - data.start) / 1000
    }
  }

  const onEvent = (type, el) => {
    data.events.push(getEvent(type, el))
  }

  let lastPersist = 0
  const persist = () => {
    const lastEvent = data.events.slice(-1).pop()
    if (lastEvent && lastEvent.time > lastPersist) {
      if (window && window.location.host === 'localhost:3000') {
        console.log('analytics', data)
      } else {
        fetch('/analytics', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        })
      }
    }
    lastPersist = (new Date() - data.start) / 1000
  }

  return {
    init: str => {
      data.token = str
      setInterval(persist, 10*1000)
    },
    onHover: el => onEvent('hover', el),
    onClick: el => onEvent('click', el),
    onPageChange: el => onEvent('page', el),
  }
})()

export default TRACKER