import styled, { keyframes, css } from 'styled-components'
import { NavLink as RouterLink } from 'react-router-dom'

const BLUE = '#d1e4f6'
const DARKBLUE = '#1c548a'
const GRAY = '#5b646b'
const BORDER = `3px solid #d1e4f6`

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const rotateIn = keyframes`
  from {
    transform: rotate3d(1, 0, 0, 90deg);
  }
  to {
    transform: rotate3d(1, 0, 0, 0deg);
  }
`

const inset = css`
  background-color: ${GRAY};
  color: transparent;
  text-shadow: 2px 2px 3px rgba(255,255,255,0.5);
  -webkit-background-clip: text;
    -moz-background-clip: text;
          background-clip: text;
`

export const Page = styled.div`
  animation: ${fadeIn} 2.5s;
  width: 550px;
  margin: auto;
  margin-bottom: 7rem;
  @media only screen and (max-width: 600px) {
    max-width: 100%;
  }
  color: ${GRAY};
  font-size: 1.2rem;
  font-family: DIN, Verdana, Arial, sans-serif;
`


export const Header = styled.h1`
  text-align: center;
  font-size: 5.8rem;
  letter-spacing: -0.5rem;
  @media only screen and (max-width: 600px) {
    font-size: 4rem;
    letter-spacing: -0.25rem;
  }
  @media only screen and (max-width: 400px) {
    font-size: 3rem;
  }
  font-weight: normal;
  margin: 1rem 0;

  ${inset}
  
  transform: scale(1, 1);
  transition: transform 1s;

  &:hover {
    transform: scale(1.05, 1.05);
  }
`

export const Menu = styled.div`
  border-top: ${BORDER};
  border-bottom: ${BORDER};
  display: flex;
  justify-content: space-between;
  @media only screen and (min-width: 600px) {
    margin-bottom: 2.5rem;
  }
  font-weight: bold;
`

export const MenuItem = styled(RouterLink)`
  text-decoration: none;
  flex-grow: 1;
  text-align: center;
  font-size: 1.4rem;
  @media only screen and (max-width: 400px) {
    font-size: 1rem;
  }
  padding: .25rem 0;

  ${inset}

  position: relative;
  &::before {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${BLUE};
    transform-origin: center top;
    transform: scaleY(0);
    transition: transform 0.25s ease-in-out;
  }
  &:hover::before {
    transform-origin: center bottom;
    transform: scaleY(1);
  }

  &.active {
    background-color: ${DARKBLUE};
  }
`

export const Content = styled.div`
  padding: 2rem;
  padding-bottom: 2.5rem;
  & > * {
    animation: ${fadeIn} 1s;
  }
  @media only screen and (max-width: 400px) {
    padding: 1rem;
  }
  @media only screen and (max-width: 600px) {
    border-bottom: ${BORDER};
  }
  @media only screen and (min-width: 600px) {
    border-right: 2px solid ${BLUE};
    border-bottom: 3px solid ${BLUE};
    box-shadow: 5px 7px 20px #ddd;
  }
`

export const Headline = styled.h2`
  font-size: 2rem;
  font-weight: normal;
  letter-spacing: -0.1rem;
  margin-bottom: 1.5rem;
`

export const SubHeadline = styled.h3`
  font-size: 1.7rem;
  font-weight: normal;
  letter-spacing: -0.1rem;
  margin-bottom: 1rem;
`

export const Paragraph = styled.p`
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
  line-height: 1.7rem;
`

const link = css`
  text-decoration: none;
  color: ${DARKBLUE};
  transition: background-color .25s;
  &:hover {
    background-color: ${BLUE};
  }
  border-radius: .5rem;
  padding: 0 5px;
  margin: 0 -5px;
`

export const Link = styled.a`
  ${link}
`
export const InternalLink = styled(RouterLink)`
  ${link}
`

export const UnstyledLink = styled.a`

`