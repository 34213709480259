import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App';
import './global.css';
import * as serviceWorker from './serviceWorker';

const renderMethod = !!module.hot ? ReactDOM.render : ReactDOM.hydrate

const getToken = () => {
  const el = document.getElementById('token')
  return el ? el.innerText : 'no-token'
}

renderMethod(<Router><App token={getToken()} /></Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
