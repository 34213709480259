import * as React from 'react';

import { Link as StyledLink, UnstyledLink } from './styles'

import tracker from '../tracking'

export class Link extends React.Component {
  render() {
    const { href, external, children, styled } = this.props
    const Component = styled ? StyledLink : UnstyledLink
    return (
      <Component 
        href={href}
        target={external ? '_blank' : '_self'}
        rel={external ? 'noopener noreferrer' : ''}
        onMouseEnter={() => tracker.onHover(children)}
        onClick={() => tracker.onClick(children)}
        onAuxClick={() => tracker.onClick(children)}
      >
        {children}
      </Component>
    );
  }
}

export default Link;