import React from 'react';

import { Content, Headline, SubHeadline } from '../components'
import tracker from '../tracking'


class About extends React.Component {
  componentDidMount() {
    tracker.onPageChange('404')
  }
  render() {
    return (
      <Content>
        <Headline>404</Headline>
        <SubHeadline>not found</SubHeadline>
      </Content>
    );
  }
}

export default About;