import React from 'react';
import styled from 'styled-components'

import tracker from '../tracking';

const ImageContainer = styled.span`
  height: 200px;
  width: 140px;
  position: relative;
  display: block;
  float: right;
  margin-left: 2rem;

  & > img {
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity .25s;
  }
  & > img.blue:hover {
    opacity: 0;
  }
`

export class HoverImage extends React.Component {
  render() {
    return (
      <ImageContainer>  
        <img src="/assets/me.jpg" alt="Thomas Misera" />
        <img 
          src="/assets/me-blue.jpg" 
          alt="" 
          className="blue"
          onMouseEnter={() => tracker.onHover('me')}
          onClick={() => tracker.onClick('me')}
          onAuxClick={() => tracker.onClick('me')}
        />
      </ImageContainer>
    )
  }
}

export default HoverImage