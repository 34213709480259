import * as React from 'react';
import styled from 'styled-components'

import Link from './Link'

const Container = styled.div`
  display: inline-block;
  padding-right: 1rem;
  padding-bottom: 1rem;
`

const HoverImage = styled.img`
  max-height: 3rem;
  filter: saturate(0);
  opacity: 0.4;
  transition: filter 0.25s, opacity 0.25s;
  &:hover {
    filter: saturate(1);
    opacity: 1;
  }
`
const stripFileExtension = (filename) => {
  return filename ? filename.split('.').slice(0,-1).join('.') : ''
}

export class ImageLink extends React.Component {
  render() {
    const { href, img } = this.props
    return (
      <Container>
        <Link href={href} external>
          <HoverImage src={`/assets/logos/${img}`} alt={stripFileExtension(img)} />
        </Link>
      </Container>
    );
  }
}

export default ImageLink;