import * as React from 'react'
import styled from 'styled-components'

import { Link } from './Link'

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
  font-size: 0.75rem;
  text-transform: uppercase;
`

const Year = styled.span`
  line-height: 1.75rem;
`

const LinkStyles = styled.div`
  line-height: 1.75rem;
  padding: 0 .25rem;
`

export class Footer extends React.Component {
  render() {
    return (
      <Container>
        <Year>{'AC'}</Year>
        <LinkStyles>
          <Link href="https://acxx.de" external styled>XX</Link>
        </LinkStyles>
        <Year>{new Date().getFullYear()}</Year>
      </Container>
    );
  }
}

export default Footer;