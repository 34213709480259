import React from 'react';
import styled from 'styled-components'

import { Content, Headline, Paragraph } from '../components'
import { ImageLink } from '../components/ImageLink'
import { Link } from '../components/Link'
import tracker from '../tracking'

class Email extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onClient: false
    };
  }
  componentDidMount() {
    this.setState({ onClient: true })
  }
  render() {
    if (this.state.onClient) {
      const email = 'thomas@misera.org'
      return <Link href={`mailto:${email}`} styled>{email}</Link>
    }
    return null
  }
}

const Icons = styled.div`
  float: right;
`


class Contact extends React.Component {
  componentDidMount() {
    tracker.onPageChange('contact')
  }
  render() {
    return (
      <Content>
        <Headline>contact me</Headline>
        <Icons>
          <ImageLink 
            href="https://www.linkedin.com/in/thomas-misera"
            img="linkedin.png"
          />
          <ImageLink 
            href="https://github.com/modemuser"
            img="github.png"
          />
          <br />
          <ImageLink 
            href="https://facebook.com/thomas.misera"
            img="facebook.png"
          />
          <ImageLink 
            href="https://instagram.com/modemuser"
            img="instagram.png"
          />
        </Icons>
        <Paragraph>
            Thomas Misera<br/>
            Lothringerstr. 97<br/>
            52070 Aachen<br/>
            Germany
        </Paragraph>
        <Paragraph>
            Phone +49 241 46376345<br/>
            Mobile +49 178 3316064<br/>
            Email <Email />
        </Paragraph>
      </Content>
    );
  }
}

export default Contact;