import React from 'react'
import Helmet from 'react-helmet'

import { Content, Headline, Paragraph, SubHeadline } from '../components'
import tracker from '../tracking'


class Bank extends React.Component {
  componentDidMount() {
    tracker.onPageChange('bank')
  }
  render() {
    return (
      <Content>
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <Headline>bank details</Headline>
        
        <SubHeadline>business</SubHeadline>

        <Paragraph>Postbank Dortmund</Paragraph>
        <Paragraph>
          Konto: 102114461<br/>
          BLZ: 44010046
        </Paragraph>

        <Paragraph>
          IBAN: DE02 4401 0046 0102 1144 61<br/>
          BIC: PBNKDEFF
        </Paragraph>

        <br/>

        <SubHeadline>private</SubHeadline>

        <Paragraph>Postbank Dortmund</Paragraph>
        <Paragraph>
          Konto: 447133466<br/>
          BLZ: 44010046
        </Paragraph>

        <Paragraph>
          IBAN: DE13 4401 0046 0447 1334 66<br/>
          BIC: PBNKDEFF
        </Paragraph>
      </Content>
    );
  }
}

export default Bank;